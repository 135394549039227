import React, { useContext } from "react";

import { SingleContext } from "gatsby-frontend/src/Page";
import {
	TitleBlog,
	Image,
	Text,
	Blocks,
	List,
} from "gatsby-frontend/plugins/gatsby-frontend-material";
import { Box, CircularProgress, Paper, Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function ImageBody() {
	const theme = useTheme();
	const singleContext = useContext(SingleContext);

	const {
		attributes,
		logoPrint,
		title,
		hasDates,
		dates,
		dateFormat,
		image,
		vCard,
		isReversed,
		title_append,
		shareButtons,
		hasMainSection,
		hasBody,
		relationships,
		hasBodyMedia,
		media,
	} = singleContext;

	if (!attributes) {
		return (
			<Box sx={{ textAlign: "center" }}>
				<CircularProgress sx={{ margin: "2rem auto" }} />
			</Box>
		);
	}

	return (
		<main>
			<Box
				className="print-only"
				sx={{ display: "none", "& img": { width: 200, height: "auto" } }}
			>
				<img src={logoPrint} alt="logo" />
			</Box>

			<Container
				sx={{ padding: theme.spacing(3, 0) }}
				className="main-container image-layout-container"
			>
				<Box className="main-wrapper">
					<Grid container className="main-grid-container" spacing={0}>
						<TitleBlog
							title={title}
							title_append={title_append}
							subtitle={attributes.field_subtitle}
							hasDates={hasDates}
							dates={dates}
							dateFormat={dateFormat}
							image={image}
							vCard={vCard}
							isReversed={isReversed}
							shareButtons={shareButtons}
						/>
						<Grid className="main-grid-left" item xs={12}>
							<Grid container spacing={4}>
								<Grid item sm={8}>
									<Box
										className="fixed-featured-image"
										sx={{ marginBottom: theme.spacing(3) }}
									>
										<Image {...image} orientation="full" />
									</Box>

									{hasBody && (
										<Text
											html={
												attributes.body.transformed
													? attributes.body.transformed
													: attributes.body.value
											}
										/>
									)}

									{hasBodyMedia && (
										<List
											{...media}
											useElastic={false}
											cols={2}
											layout="topbar"
											sx={{ marginTop: theme.spacing(3) }}
										/>
									)}
								</Grid>
								<Grid item sm={4}>
									{hasMainSection && (
										<Box
											className="body-box"
											component={Paper}
											elevation={3}
											sx={{
												padding: theme.spacing(2, 3),
											}}
										>
											<Blocks
												data={relationships.field_section_main.data}
												className="main-section"
											/>
										</Box>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</main>
	);
}

export default ImageBody;
