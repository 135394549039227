import React from "react";
import { fetchServerData } from "gatsby-frontend";

import Image from "./Image";

function ImageSSR({ children }) {
	return <Image>{children}</Image>;
}

export default ImageSSR;

export async function getServerData({ pageContext }) {
	return await fetchServerData(pageContext);
}
