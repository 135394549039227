import React from "react";
import { SingleWrapper } from "gatsby-frontend/plugins/gatsby-frontend-material";
import ImageBody from "./ImageBody";

function Image() {
	return (
		<SingleWrapper>
			<ImageBody />
		</SingleWrapper>
	);
}

export default Image;
